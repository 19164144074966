import { makeStyles } from '@material-ui/core/styles';
import theme from "../config/Theme";

//Since we are using material ui I wanna try useStyles hook
const useStyles = makeStyles(theme => ({
    search:{
        marginBottom: 20,
        marginRight: "auto",
        width: 300,
    },
    autocomplete:{
        marginBottom: 20,
        marginRight: "auto",
        width: 500,
    }
}),{defaultTheme: theme});

export { useStyles };

