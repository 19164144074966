import React, {useEffect, useState} from 'react'
import { Paper, Typography, CardActions, Card, CircularProgress} from "@material-ui/core";
import { useStyles } from "../../styles/CommonStyles";
import { useTranslation } from 'react-i18next';
import AutocompleteStyled from '../common/AutocompleteStyled';

import { findAllInternalCodes, InternalCodeDto, StandardDto, findByInternalCodes } from '../../api/StandardApi'
import BackendError from '../common/BackendError';
import StandardsTable from '../standards/StandardsTable';
import ButtonStyled from '../common/ButtonStyled';
import { navigate } from 'gatsby-link';
import { createLinkGroup, LinkGroupDto } from '../../api/LinkGroupApi';
import SearchStyled from '../common/SearchStyled';
import ConfirmDialog from '../common/ConfirmDialog';
import ProductStandardLinkingDetail from './ProductStandardLinkingDetail';
import Header from '../common/Header';
import { findProductById, ProductDto } from '../../api/ProductApi';
import InfoHover from '../common/InfoHover';

interface ProductStandardLinkingProps{
    productId: number,
    style?: any,
}
export default function ProductStandardLinking( props: ProductStandardLinkingProps){
    const classes = useStyles();
    const { t,i18n } = useTranslation();

    const [loading, setLoading] = useState<boolean>(true)
    const [loadingRows, setLoadingRows] = useState<boolean>(false)
    const [backendErrorMessage, setBackendErrorMessage] = useState(null)

    const [saveDialogOpen, setSaveDialogOpen] = useState(false)
    const [linkGroupData, setLinkGroupData] = useState<LinkGroupDto>(null)

    const [internalCodesFull, setInternalCodesFull] = useState<InternalCodeDto[]>([]);
    const [internalCodesEMDN, setInternalCodesEMDN] = useState<InternalCodeDto[]>([]);
    const [internalCodesMDA, setInternalCodesMDA] = useState<InternalCodeDto[]>([]);
    const [internalCodesMDN, setInternalCodesMDN] = useState<InternalCodeDto[]>([]);
    const [internalCodesMDS, setInternalCodesMDS] = useState<InternalCodeDto[]>([]);
    const [internalCodesMDT, setInternalCodesMDT] = useState<InternalCodeDto[]>([]);
    const [icodesFilterEMDN, setIcodesFilterEMDN] = useState<InternalCodeDto>(null);
    const [icodesFilterMDA, setIcodesFilterMDA] = useState<InternalCodeDto>(null);
    const [icodesFilterMDN, setIcodesFilterMDN] = useState<InternalCodeDto>(null);
    const [icodesFilterMDS, setIcodesFilterMDS] = useState<InternalCodeDto[]>([]);
    const [icodesFilterMDT, setIcodesFilterMDT] = useState<InternalCodeDto[]>([]);

    const [fullTextSearchFilter, setFullTextSearchFilter] = useState<string>("");

    const [standardsByIcodes, setStandardsByIcodes] = useState<StandardDto[]>([]);
    const [rowsPerPageLimit1, setRowsPerPageLimit1] = useState<number>(5);
    const [currentPageOffset1, setCurrentPageOffset1] = useState<number>(0);
    const [rowsPerPageLimit2, setRowsPerPageLimit2] = useState<number>(5);
    const [currentPageOffset2, setCurrentPageOffset2] = useState<number>(0);
    const [standardsBySearch, setStandardsBySearch] = useState<StandardDto[]>([]);

    const [currentProduct, setCurrentProduct] = useState<ProductDto>(null)


    const handleChangeRowsPerPage1 = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPageLimit1(+event.target.value);
        setCurrentPageOffset1(0);
    };

    const handleChangePage1 = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setCurrentPageOffset1(newPage);
    };

    const handleChangeRowsPerPage2 = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        var limit = parseInt(event.target.value, 10)
        //getCurrentRows(limit, 0, filterByNameContaining, manufacturerFilter, keywordFilter)
        setRowsPerPageLimit2(limit);
        setCurrentPageOffset2(0)
    };

    const handleChangePage2 = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        //getCurrentRows(rowsPerPageLimit, newPage, filterByNameContaining, manufacturerFilter, keywordFilter)
        setCurrentPageOffset2(newPage)
    };

    const [searchedAtLeastOnce, setSearchedAtLeastOnce] = useState<boolean>(false);

    useEffect(() => {
        findProductById(props.productId).then(data => {
            setCurrentProduct(data)
        }).catch(err => {
            console.log("Somethign went wrong")
        })
        findAllInternalCodes(null,null).then(data =>{
            setInternalCodesFull(data)
            let tmpEMDN = [];
            let tmpMDA = [];
            let tmpMDN = [];
            let tmpMDS = [];
            let tmpMDT = [];
            data.forEach(icode => {
                switch(icode.type){
                    case "EMDN": tmpEMDN.push(icode); break;
                    case "MDA": tmpMDA.push(icode); break;
                    case "MDN": tmpMDN.push(icode); break;
                    case "MDS": tmpMDS.push(icode); break;
                    case "MDT": tmpMDT.push(icode); break;
                    default: break;
                }
            })
            setInternalCodesEMDN(tmpEMDN);
            setInternalCodesMDA(tmpMDA);
            setInternalCodesMDN(tmpMDN);
            setInternalCodesMDS(tmpMDS);
            setInternalCodesMDT(tmpMDT);
            setLoading(false)
        }).catch(err => {
            (err.response!=null && err.response.data.message!=null) ? setBackendErrorMessage(err.response.data.message) : setBackendErrorMessage(t("ERRDATAERROR"))
        })
    },[])

    const getRows = () => {
        setLoadingRows(true)
        if(fullTextSearchFilter.length>0 && fullTextSearchFilter.length<3){
            setBackendErrorMessage(t("SEARCHTEXTATLEAST3"))
        }else{
            setBackendErrorMessage(null)
        }
        setSearchedAtLeastOnce(true)
        let allIcodesFilter = (icodesFilterEMDN ? icodesFilterEMDN.id+"," : "") + (icodesFilterMDA ? icodesFilterMDA.id+"," : "") + (icodesFilterMDN ? icodesFilterMDN.id+"," : "") + icodesFilterMDS.map(icode => icode.id+"") + icodesFilterMDT.map(icode => icode.id+"")
        findByInternalCodes(2,fullTextSearchFilter,allIcodesFilter).then(data=>{
            data.first.forEach(row => {
                row.included = true
            })
            setStandardsByIcodes(data.first)
            data.second.forEach(row => {
                row.included = true
            })
            setStandardsBySearch(data.second)
            setLoadingRows(false)
        }).catch(err =>{
            (err.response!=null && err.response.data.message!=null) ? setBackendErrorMessage(err.response.data.message) : setBackendErrorMessage(t("ERRDATAERROR"))
        })
    }

    const continueToDetail = () => {
        if(searchedAtLeastOnce==false){
            setBackendErrorMessage(t("SEARCHATLEASTONCE"))
            return
        }
        if(icodesFilterEMDN==null || icodesFilterMDA==null || icodesFilterMDN==null || icodesFilterMDT.length==0){
            setBackendErrorMessage(t("NOFILTERS"))
            return
        }
        setBackendErrorMessage(null)
        let includedStandards = []
        let exludedStandards = []
        standardsByIcodes.forEach(standard => {
            standard.included==true ? includedStandards.push(standard) : exludedStandards.push(standard)
        })
        standardsBySearch.forEach(standard => {
            standard.included==true ? includedStandards.push(standard) : exludedStandards.push(standard)
        })
        setLinkGroupData({emdnFilter:icodesFilterEMDN,mdaFilter:icodesFilterMDA,mdnFilter:icodesFilterMDN,mdsFilter:icodesFilterMDS,mdtFilter:icodesFilterMDT,fulltextSearch:fullTextSearchFilter,standards:includedStandards,removedStandards:exludedStandards})
    }

    const saveLinking = () => {
        createLinkGroup({idProduct:props.productId,mdaFilter:linkGroupData.mdaFilter?.id,mdnFilter:linkGroupData.mdnFilter?.id,emdnFilter:linkGroupData.emdnFilter?.id,mdsFilter:linkGroupData.mdsFilter.map(icode => icode.id),mdtFilter:linkGroupData.mdtFilter.map(icode => icode.id),fulltextSearch:linkGroupData.fulltextSearch,standards:linkGroupData.standards.map(std=>std.id),removedStandards:linkGroupData.removedStandards.map(std=>std.id)}).then(data =>{
            navigate("/products/"+data.idProduct+"/link-history?latest")
        }).catch(error =>{
            if(error.response!=undefined) {
                setBackendErrorMessage(i18n.language=='cs' ? error.response.data.czechMessage!=null ? error.response.data.czechMessage : error.response.data.message : error.response.data.message);
            }else setBackendErrorMessage(t("ERRDATAERROR"));
            setSaveDialogOpen(false)
        })
    }

    useEffect(()=>{
        getRows();
    },[icodesFilterEMDN,icodesFilterMDA,icodesFilterMDN,icodesFilterMDS,icodesFilterMDT])

    if(loading){
        return (
            <div style={{display: 'flex', justifyContent: 'center',marginTop: 200}}><CircularProgress color="secondary"/></div>
        )
    }else{
    return (
        <Paper className={classes.root} style={props.style}>
            <Header title={t("LINKSTANDARDS")} subheader={currentProduct?.name}/>
            {backendErrorMessage!=null && <BackendError error={backendErrorMessage}/>}
            {linkGroupData==null ? 
            <div style={{display:"flex",justifyContent:"space-between"}}>
                <div style={{paddingTop:40, marginRight: 20,width: "40%"}}>
                    <AutocompleteStyled label={t("ICODESFILTER")+" EMDN"} value={icodesFilterEMDN} multiple={false}
                                        options={internalCodesEMDN!=null ? internalCodesEMDN : []}
                                        customOptionLabel={true} optionLabel="name" optionLabel2="description"
                                        handleValueFunction={setIcodesFilterEMDN} style={{width: "100%", minWidth: 300}}/>
                    <AutocompleteStyled label={t("ICODESFILTER")+" MDA"} value={icodesFilterMDA} multiple={false}
                                        options={internalCodesMDA!=null ? internalCodesMDA : []}
                                        customOptionLabel={true} optionLabel="name" optionLabel2="description"
                                        handleValueFunction={setIcodesFilterMDA} style={{width: "100%", minWidth: 300}}/>
                    <AutocompleteStyled label={t("ICODESFILTER")+" MDN"} value={icodesFilterMDN} multiple={false}
                                        options={internalCodesMDN!=null ? internalCodesMDN : []}
                                        customOptionLabel={true} optionLabel="name" optionLabel2="description"
                                        handleValueFunction={setIcodesFilterMDN} style={{width: "100%", minWidth: 300}}/>
                    <AutocompleteStyled label={t("ICODESFILTER")+" MDS"} value={icodesFilterMDS} multiple={true}
                                        options={internalCodesMDS!=null ? internalCodesMDS : []}
                                        customOptionLabel={true} optionLabel="name" optionLabel2="description"
                                        handleValueFunction={setIcodesFilterMDS} style={{width: "100%", minWidth: 300}}/>
                    <AutocompleteStyled label={t("ICODESFILTER")+" MDT"} value={icodesFilterMDT} multiple={true}
                                        options={internalCodesMDT!=null ? internalCodesMDT : []}
                                        customOptionLabel={true} optionLabel="name" optionLabel2="description"
                                        handleValueFunction={setIcodesFilterMDT} style={{width: "100%", minWidth: 300}}/>
                    <SearchStyled text={t("FULLTEXTSEARCH")} style={{width: "100%", minWidth: 300}} value={fullTextSearchFilter} handleValueFunction={setFullTextSearchFilter}/>
                    <div style={{width:240, marginRight: "auto", marginLeft:"auto", display:"block",marginBottom:200}}>
                        <InfoHover style={{float:"right"}} info={t("SEARCHINFO")} />
                        <ButtonStyled style={{float:"left"}} text={t("SEARCH")} onClick={() => getRows()}/>                   
                    </div>
                </div>
                {loadingRows==false ? 
                <div style={{paddingTop:20, width: "60%"}} >
                    <Typography className={classes.fieldTitle} variant="body1">
                        {t("STANDARDSBYICODES")}
                    </Typography>
                    <StandardsTable showUrl={true} showCheckboxes={true} style={{width: "100%"}} rows={standardsByIcodes.slice(currentPageOffset1 * rowsPerPageLimit1, currentPageOffset1 * rowsPerPageLimit1 + rowsPerPageLimit1)} count={standardsByIcodes.length} page={currentPageOffset1} handleChangePage={handleChangePage1} rowsPerPage={rowsPerPageLimit1} handleChangeRowsPerPage={handleChangeRowsPerPage1} linkInNewWindow={true}/>
                    <Typography style={{paddingTop: 10}} className={classes.fieldTitle} variant="body1">
                        {t("STANDARDSBYSEARCH")}
                    </Typography>
                    <StandardsTable showUrl={true} showCheckboxes={true} style={{width: "100%"}} rows={standardsBySearch.slice(currentPageOffset2 * rowsPerPageLimit2, currentPageOffset2 * rowsPerPageLimit2 + rowsPerPageLimit2)} count={standardsBySearch.length} page={currentPageOffset2} handleChangePage={handleChangePage2} rowsPerPage={rowsPerPageLimit2} handleChangeRowsPerPage={handleChangeRowsPerPage2} linkInNewWindow={true}/>
                </div> : 
                <div style={{paddingTop:20, width: "60%",display: 'flex', justifyContent: 'center', margin:"auto"}}><CircularProgress color="secondary"/></div>
                }
            </div>
            : <ProductStandardLinkingDetail linkGroup={linkGroupData}/>}
            {linkGroupData==null ? 
                <CardActions className={classes.cardActions}>
                    <ButtonStyled
                        style={{marginRight: "auto"}}
                        text={t("BACK")}
                        onClick={() => navigate("/products/"+props.productId)}/>
                    <ButtonStyled
                        style={{marginLeft: "auto", display:"block"}}
                        text={t("CONTINUE")}
                        onClick={() => continueToDetail()}/>
                </CardActions>
            :   <CardActions className={classes.cardActions}>
                    <ButtonStyled
                        style={{marginRight: "auto"}}
                        text={t("BACK")}
                        onClick={() => setLinkGroupData(null)}/>
                    <ButtonStyled
                        text={t("SAVELINKING")}
                        style={{marginLeft: "auto", display:"block"}}
                        onClick={() => setSaveDialogOpen(true)}/>
                    <ConfirmDialog
                            title={t("SAVELINKING")}
                            open={saveDialogOpen}
                            onClose={()=>setSaveDialogOpen(false)}
                            onConfirm={saveLinking}
                        >
                            {t("SAVELINKINGCONFIRM")}
                    </ConfirmDialog>
            </CardActions>}
        </Paper>
    )}
}