import React from 'react'
import { TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";

import { useStyles } from "../../styles/SearchAutocompleteStyles";

interface SearchStyledProps{
    text:string,
    value:any,
    handleValueFunction:any
    style?:any,
}

export default function SearchStyled(props: SearchStyledProps){
    const classes = useStyles();

    return (
        <TextField
            label={props.text}
            type="text"
            size="small"
            variant="filled"
            style={props.style}
            value={props.value}
            className={classes.search}
            InputProps={{
                endAdornment: props.value=="" ? (
                    <SearchIcon/>
                ) : (
                    <CancelRoundedIcon style={{cursor: "pointer"}} onClick={()=>props.handleValueFunction("")}/>
                )
            }}
            onChange={(event) => props.handleValueFunction(event.target.value)}/>
    )
}