import React from 'react'
import {Chip, TextField} from "@material-ui/core";
import {useStyles} from "../../styles/SearchAutocompleteStyles";
import { useTranslation } from 'react-i18next';

import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";


interface AutocompleteStyledProps{
    label:string,
    value:any,
    multiple:boolean,
    options:any[],
    handleValueFunction?:any,
    style?:any,
    variant?:"filled"|"standard",
    className?:any,
    freeSolo?:boolean,
    customOptionLabel?:boolean,
    optionLabel?:any,
    optionLabel2?:any,
}

export default function AutocompleteStyled(props: AutocompleteStyledProps){
    const classes = useStyles();
    const { t } = useTranslation();

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        limit: 300,
      });

    return (
        <Autocomplete
            multiple={props.multiple}
            style={props.style}
            className={`${props.className} ${classes.autocomplete}`}
            id={props.label}
            size="small"
            value={props.value}
            autoSelect={props.freeSolo!=null ? props.freeSolo : false}
            options={props.options!=null ? props.options : []}
            getOptionLabel={props.customOptionLabel==true ? option => option[props.optionLabel]+(props.optionLabel2!=null?(" - ")+option[props.optionLabel2]:"") : option => option}
            renderOption={props.customOptionLabel==true ? option => <>{option[props.optionLabel]}{props.optionLabel2!=null?<div style={{fontSize:12}}>{("- ") + option[props.optionLabel2]}</div>:<div/>}</> : option => option}
            freeSolo={props.freeSolo!=null ? props.freeSolo : false}
            filterSelectedOptions
            filterOptions={filterOptions}
            noOptionsText={t("NOOPTIONS")}
            renderTags={(value: string[], getTagProps) =>
                value.map((option: string, index: number) => (
                    <Chip variant="outlined" label={props.customOptionLabel==true ? option[props.optionLabel]+(props.optionLabel2!=null?(" - ")+option[props.optionLabel2]:"") : option} {...getTagProps({ index })} />
                ))
            }
            renderInput={(params) => (
                <TextField {...params} variant={props.variant!=null ? props.variant : "filled"} label={props.label} placeholder={props.multiple ? t("SELECTMULTIPLE") : t("SELECTONE")} />
            )}
            onChange={(event,values) => props.handleValueFunction(values)}
        />
    )
}