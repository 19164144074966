import React from 'react';
import Layout from '../../../components/Layout';

import ProductStandardLinking from '../../../components/products/ProductStandardLinking';

export default function ProductStandardLinkingPage( props ) {

    return (
        <Layout allowAnonymous={false}>
            <ProductStandardLinking style={{minWidth:1000, width:"100%", marginLeft: "auto", marginRight: "auto"}} productId={props.params.productId}/>
        </Layout>
    )
}